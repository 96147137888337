import { faSearch } from "@jewlr/pro-light-svg-icons/faSearch"
import { faUser } from "@jewlr/pro-light-svg-icons/faUser"
import {
  Box,
  Button,
  Flex,
  MediaBox,
  FontAwesomeIcon,
  Image,
} from "@jewlr/storybook/core"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import {
  clearSearchUserInput,
  clearSearchSuggestions,
} from "app-store-v2/app/actions"
import CartIcon from "areas/header/dropdowns/cart-icon"
import withHeader from "areas/header/hoc/with-header"
import HeaderSearch from "areas/header/jewlr/search/search"
import WishlistIcon from "areas/header/jewlr/wishlist-icon"
import { IS_JEWLR } from "helpers/application"

const Header = styled(MediaBox).attrs({
  lessThan: "desktop",
})`
  background-color: white;
  box-shadow: 0 4px 4px 0px rgba(49, 49, 49, 0.15);
  display: flex;
  position: relative;
  width: 100%;
  ${(props) =>
    props.empty && IS_JEWLR()
      ? `
          height: 106px;
          max-height: 106px;
          min-height: 106px;
          ${props.theme.mediaQueries.tablet`
            height: 98px;
            max-height: 98px;
            min-height: 98px;
          `}
        `
      : `
        height: 58px;
        max-height: 58px;
        min-height: 58px;
      `}
`

const HeaderIcon = styled(Box)`
  margin-left: 20px;
  padding: 0;
  ${(props) => props.theme.mediaQueries.tablet`
    margin-left: 24px;
  `}
  :first-child {
    margin-left: 0;
  }
`

const MenuRow = styled(Flex)`
  height: 58px;
  justify-content: space-between;
  max-height: 58px;
  min-height: 58px;
  padding: 0 16px;
  width: 100%;
`

const FlexRow = styled(Flex)`
  align-items: center;
`

const MenuIcon = styled(Button).attrs({
  variant: "text",
})`
  background-color: ${(props) => props.theme.colors.white};
  height: 22px;
  margin-right: 16px;
  width: 20px;
`
const Hamburger = styled.div`
  left: 18px;
  top: 27px;

  &&,
  :before,
  :after {
    background-color: ${(props) => props.theme.colors.black};
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease 0.2s, top ease 0.2s 0.2s, transform ease 0.2s;
    width: 20px;
  }
  :before {
    top: -7px;
  }
  :after {
    top: 7px;
  }
  ${(props) =>
    props.close &&
    `
    && {
      background-color: transparent;
    }
    :before, :after {
      top: 0;
      transition: top ease .2s, transform ease .2s .2s;
    }
    :before {
      transform: rotate(45deg);
    }
    :after {
      transform: rotate(-45deg);
    }
  `}
`

const MobileHeader = ({
  clearSearch,
  empty,
  location,
  showMenu,
  toggleMenu,
  showAccountMenu,
  toggleAccountMenu,
  loggedIn,
}) => {
  const [searching, setSearch] = useState(false)
  const [geo, setGeo] = useState(null)
  const searchInputRef = useRef()
  const searchBtnRef = useRef()
  const restoreSearchFocus = useRef()

  useEffect(() => {
    toggleMenu(true)
    toggleAccountMenu(true)
    setSearch(false)
    clearSearch()
  }, [location.pathname])

  useEffect(() => {
    setGeo(Cookies.get("geo"))
  }, [geo])

  useEffect(() => {
    if (!searching && restoreSearchFocus.current && searchBtnRef.current) {
      searchBtnRef.current.focus()
      restoreSearchFocus.current = false
    }
  }, [searching, searchBtnRef.current])

  const toggleSearchBar = (e) => {
    e.preventDefault()
    setSearch(!searching)
    toggleMenu(true)
    toggleAccountMenu(true)
  }

  const handleCloseSearch = () => {
    restoreSearchFocus.current = true
    setSearch(false)
  }

  if (empty) {
    return <Header empty />
  } else {
    return (
      <Header>
        {searching ? (
          <FlexRow>
            <HeaderSearch
              handleCloseSearch={handleCloseSearch}
              isMobile
              searchInputRef={searchInputRef}
            />
          </FlexRow>
        ) : (
          <MenuRow>
            <FlexRow>
              <MenuIcon
                aria-controls="mobile-menu"
                aria-expanded={showMenu}
                aria-haspopup="true"
                aria-label="Menu"
                data-cy="header-menu-icon-mobile"
                id="js_menu_toggle"
                onClick={() => toggleMenu(showMenu)}
              >
                <Hamburger close={showMenu} />
              </MenuIcon>

              <Box as={Link} data-lc="home" display="block" to="/">
                {IS_JEWLR() ? (
                  <Image
                    alt="Jewlr"
                    height="24px"
                    ratio="696 / 142"
                    src={require("images/layout/logo/2024_Jewlr_Logo.svg")}
                    width="107.95px"
                  />
                ) : (
                  <Image
                    alt={sl.config.site_title}
                    height="20px"
                    ratio="163 / 22"
                    src={require("images/portal/Safyre_Labs_Logo.svg")}
                    width="154px"
                  />
                )}
              </Box>
            </FlexRow>
            <FlexRow flexGrow="1" justifyContent="flex-end">
              {IS_JEWLR() && (
                <>
                  <HeaderIcon
                    aria-label={loggedIn ? "My Account" : "Sign In"}
                    as={Button}
                    data-cy="menu-sign-in-mobile"
                    onClick={() => toggleAccountMenu(showAccountMenu)}
                    variant="text"
                    {...(loggedIn && {
                      "aria-controls": "mobile-menu-account",
                      "aria-expanded": showAccountMenu,
                      "aria-haspopup": "true",
                    })}
                  >
                    <FontAwesomeIcon fontSize={20} icon={faUser} />
                  </HeaderIcon>

                  <HeaderIcon>
                    <WishlistIcon />
                  </HeaderIcon>

                  <HeaderIcon
                    aria-label="Search"
                    as={Button}
                    data-cy="header-mobile-search-icon"
                    onClick={(e) => toggleSearchBar(e)}
                    ref={searchBtnRef}
                    variant="text"
                  >
                    <FontAwesomeIcon fontSize={20} icon={faSearch} />
                  </HeaderIcon>
                </>
              )}
              <HeaderIcon>
                <CartIcon isMobile />
              </HeaderIcon>
            </FlexRow>
          </MenuRow>
        )}
      </Header>
    )
  }
}

MobileHeader.propTypes = {
  clearSearch: PropTypes.func,
  empty: PropTypes.bool,
  location: PropTypes.object,
  loggedIn: PropTypes.bool,
  showAccountMenu: PropTypes.bool,
  showMenu: PropTypes.bool,
  toggleAccountMenu: PropTypes.func,
  toggleMenu: PropTypes.func,
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearSearch() {
      dispatch(clearSearchUserInput())
      dispatch(clearSearchSuggestions())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHeader(withRouter(MobileHeader)))
