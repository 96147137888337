import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import { withRouter } from "react-router-dom"

import portalMenuData from "areas/header/jewlr/menu/data-portal"
import { IS_PORTAL, retry } from "helpers/application"
import useWindowDimensions from "helpers/window-dimensions"

import HeaderContent from "./header-content"
import HeaderPush from "./header-push"

const AccountMenu = loadable(() =>
  retry(() => import("areas/header/jewlr/menu/mobile/account-menu"))
)
const MobileMenu = loadable(() =>
  retry(() => import("areas/header/jewlr/menu/mobile/main-menu"))
)

export const HeaderMenuContext = createContext()

const Header = ({ location, menu }) => {
  const [sticky, setSticky] = useState(false)
  const isTablet = useWindowDimensions().isTablet

  if (
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/payments") ||
    (IS_PORTAL() && location.pathname.includes("/order"))
  ) {
    return ""
  }

  return (
    <HeaderMenuContext.Provider
      value={{ menu: IS_PORTAL() ? portalMenuData() : menu }}
    >
      <HeaderPush />
      <HeaderContent
        location={location}
        setSticky={setSticky}
        sticky={sticky}
      />
      {isTablet && (
        <>
          <MobileMenu />
          <AccountMenu />
        </>
      )}
    </HeaderMenuContext.Provider>
  )
}

Header.propTypes = {
  location: PropTypes.object,
  menu: PropTypes.object,
}

export default withRouter(Header)
