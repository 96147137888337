import { faSearch } from "@jewlr/pro-light-svg-icons/faSearch"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Image,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import {
  clearSearchUserInput,
  clearSearchSuggestions,
} from "app-store-v2/app/actions"
import AccountIcon from "areas/header/dropdowns/account-icon"
import CartIcon from "areas/header/dropdowns/cart-icon"
import withHeader from "areas/header/hoc/with-header"
import WishlistIcon from "areas/header/jewlr/wishlist-icon"
import { IS_JEWLR } from "helpers/application"

import DesktopMenu from "./menu/desktop"
import Search from "./search/search"

const NavBar = styled(Flex).attrs({
  alignItems: "center",
  containWide: true,
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  justifyContent: "space-between",
  pad: true,
})``

const HeaderIcon = styled(Box)`
  margin-left: 24px;
  &:first-child {
    margin-left: 0;
  }
`

const DesktopNav = ({
  clearSearch,
  location,
  toggleAccountMenu,
  toggleMenu,
}) => {
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef()
  const searchBtnRef = useRef()
  const restoreSearchFocus = useRef()

  useEffect(() => {
    toggleMenu(true)
    toggleAccountMenu(true)
    setShowSearch(false)
    clearSearch()
  }, [location.pathname])

  useEffect(() => {
    if (!showSearch && restoreSearchFocus.current && searchBtnRef.current) {
      searchBtnRef.current.focus()
      restoreSearchFocus.current = false
    }
  }, [showSearch, searchBtnRef.current])

  const toggleSearchBar = (e) => {
    e.preventDefault()
    setShowSearch(!showSearch)
    toggleMenu(true)
    toggleAccountMenu(true)
  }

  const handleCloseSearch = () => {
    restoreSearchFocus.current = true
    setShowSearch(false)
  }

  return (
    <NavBar>
      <Box gridColumn="span 2">
        <Box as={Link} data-lc="home" display="inline-block" to="/">
          {IS_JEWLR() ? (
            <Image
              alt="Jewlr Homepage"
              lazyload={false}
              ratio="696 / 142"
              src={require("images/layout/logo/2024_Jewlr_Logo.svg")}
              width="126px"
            />
          ) : (
            <Image
              alt={sl.config.site_title}
              lazyload={false}
              ratio="163 / 22"
              src={require("images/portal/Safyre_Labs_Logo.svg")}
              width="163px"
            />
          )}
        </Box>
      </Box>
      <DesktopMenu />
      <Flex gridColumn="span 2" justifyContent="flex-end" position="relative">
        {showSearch ? (
          <Search
            handleCloseSearch={handleCloseSearch}
            searchInputRef={searchInputRef}
          />
        ) : (
          <>
            {IS_JEWLR() && (
              <>
                <HeaderIcon position="relative">
                  <AccountIcon dropdown="account" />
                </HeaderIcon>
                <HeaderIcon position="relative">
                  <WishlistIcon dropdown="wishlist" />
                </HeaderIcon>
                <HeaderIcon>
                  <Button
                    aria-label="Search"
                    display="inline-block"
                    onClick={toggleSearchBar}
                    ref={searchBtnRef}
                    variant="text"
                  >
                    <FontAwesomeIcon fontSize={20} icon={faSearch} />
                  </Button>
                </HeaderIcon>
              </>
            )}
            <HeaderIcon>
              <CartIcon />
            </HeaderIcon>
          </>
        )}
      </Flex>
    </NavBar>
  )
}

DesktopNav.propTypes = {
  clearSearch: PropTypes.func,
  location: PropTypes.object,
  toggleAccountMenu: PropTypes.func,
  toggleMenu: PropTypes.func,
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearSearch() {
      dispatch(clearSearchUserInput())
      dispatch(clearSearchSuggestions())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHeader(withRouter(DesktopNav)))
