import { Box } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import { FocusScope } from "@react-aria/focus"
import { useHover } from "@react-aria/interactions"
import PropTypes from "prop-types"
import React, { useContext, Suspense } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { deactivateSubmenu } from "app-store-v2/app/actions"
import { HeaderMenuContext } from "areas/header/jewlr"
import { retry, IS_PORTAL } from "helpers/application"

import MenuItem from "./menu-item"

const DesktopDropdown = loadable(() => retry(() => import("./dropdown")), {
  ssr: false,
})

const NavBar = styled(Box).attrs((props) => ({
  forwardedAs: "nav",
  gridColumn: "span 8",
  maxWidth: "768px",
  mx: "auto",
  px: 3,
  width: "100%",
  ...props,
}))``

const MenuWrap = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: no-wrap;
  gap: ${(props) => props.isPortal && "33px"};
  height: ${(props) => (props.isPortal ? "80px" : "62px")};
  justify-content: ${(props) => (props.isPortal ? "start" : "space-between")};
  margin: 0;
  padding: 0;
  position: relative;
`

const InternalDesktopMenu = ({ empty, leftMenu, sticky }) => {
  const { menu } = useContext(HeaderMenuContext)
  const { hoverProps } = useHover({
    onHoverEnd: () => leftMenu(),
  })

  const menuItems =
    menu &&
    menu.categories
      .filter((cat) => cat.device !== "mobile")
      .map((cat) => ({ ...cat, type: cat.type || "dropdown" }))
  // const saleItems =
  //   saleMenu && saleMenu.map((link) => ({ ...link, type: "link" }))
  // const items = [...menuItems, ...saleItems]
  const items = menuItems

  return (
    <NavBar
      aria-label="Main Navigation"
      id="nav-menu"
      sticky={sticky}
      {...hoverProps}
    >
      <FocusScope>
        <MenuWrap
          aria-orientation="horizontal"
          isPortal={IS_PORTAL()}
          role="menubar"
        >
          {!empty &&
            items.map((item, index) => (
              <MenuItem
                index={index}
                item={item}
                key={`${item.name}-${index}`}
              />
            ))}
        </MenuWrap>
      </FocusScope>

      {typeof window !== "undefined" && (
        <Suspense fallback={<div />}>
          <DesktopDropdown />
        </Suspense>
      )}
    </NavBar>
  )
}

InternalDesktopMenu.propTypes = {
  empty: PropTypes.bool,
  leftMenu: PropTypes.func,
  saleMenu: PropTypes.array,
  sticky: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    saleMenu: state.sale.menu.desktop,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    leftMenu: () => dispatch(deactivateSubmenu()),
  }
}

const DesktopMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalDesktopMenu)

export default DesktopMenu
