const components = {
  miniview: {
    wrapper: {
      top: "58px",
    },
  },
  mobileMenu: {
    container: {
      paddingTop: "24px",
    },
    navItem: {
      borderBottom: "1px solid #E8E8E8",
    },
  },
}

export default components
