import { MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import CountdownBanner from "areas/header/countdown-banner"
import stickyHeader from "areas/header/hoc/sticky-header"
import { IS_JEWLR } from "helpers/application"

import DesktopNav from "./desktop-nav"
import MobileHeader from "./mobile-header"

const DesktopHeaderContainer = styled(MediaBox).attrs((props) => ({
  bg: "white",
  borderBottom: "1px solid #ccc",
  boxShadow: props.sticky ? "0 6px 10px -6px #bdbdbd" : "none",
  greaterThanOrEqual: "desktop",
}))``

const HeaderContent = ({ sticky }) => {
  return (
    <>
      {IS_JEWLR() && <CountdownBanner />}
      <MediaBox lessThan="desktop" position="relative">
        <MobileHeader />
      </MediaBox>
      <DesktopHeaderContainer sticky={sticky}>
        <DesktopNav />
      </DesktopHeaderContainer>
    </>
  )
}

HeaderContent.propTypes = {
  sticky: PropTypes.bool,
}

export default stickyHeader(HeaderContent)
