const selectedTheme = () => {
  if (sl.config.organization === "jewlr4") {
    const jewlrTheme = require("@jewlr/storybook/themes").jewlrTheme
    const jewlrtheme = require("./theme/jewlr").default
    return { ...jewlrTheme, ...jewlrtheme }
  }
  if (sl.config.organization === "portal") {
    const jewlrTheme = require("@jewlr/storybook/themes").jewlrTheme
    const portaltheme = require("./theme/portal").default
    return { ...jewlrTheme, ...portaltheme }
  }
}

const orgTheme = () => Object.assign({}, selectedTheme(), {})

export default orgTheme
