/* eslint-disable sort-keys */

const menuData = () => ({
  categories: [
    {
      name: "Necklaces",
      type: "direct-link",
      device: "all",
      images: [],
      link: "/",
    },
    {
      name: "About Us",
      type: "direct-link",
      device: "all",
      images: [],
      link: "/about-us",
    },
  ],
})

export default menuData
